<template>
    <div class="main">
        <div class="p0">
            <strong>为保障绿色、文明、健康的平台氛围，创造良好的聊天交友环境，现公示本文明公约，请大家自觉遵守，平台24小时不间断巡查，一旦发现严重违规平台将封号处理，感谢大家的支持。</strong>
        </div>
        <br>
        <div style="font-suze: 15px; color:red">
            <pre>特别提醒：送福利、裸聊、线下等行为可能是欺诈
行为，请勿轻信和私下转账。谨防诈骗！</pre>
        </div>
        <div class="p1">用户不得制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：
            <div class="p2">(1) 反对宪法所确定的基本原则的；</div>
            <div class="p2">(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</div>
            <div class="p2">(3) 损害国家荣誉和利益的；</div>
            <div class="p2">(4) 煽动民族仇恨、民族歧视，破坏民族团结的；</div>
            <div class="p2">(5) 破坏国家宗教政策，宣扬邪教和封建迷信的；</div>
            <div class="p2">(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；</div>
            <div class="p2">(7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</div>
            <div class="p2">(8) 侮辱或者诽谤他人，侵害他人合法权益的；</div>
            <div class="p2">(9) 不遵守法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的；</div>
            <div class="p2">(10) 含有法律、行政法规禁止的其他内容的信息；</div>
        </div>
        <br>
        <div class="p1">用户不得利用“{{ bundleName }}”帐号或本服务制作、上载、复制、发布、传播如下干扰“{{ bundleName }}”正常运营，以及侵犯其他用户或第三方合法权益的内容：
            <div class="p2">(1) 含有任何性或性暗示的；</div>
            <div class="p2">(2) 含有辱骂、恐吓、威胁内容的；</div>
            <div class="p2">(3) 含有骚扰、垃圾广告、恶意信息、诱骗信息的；</div>
            <div class="p2">(4) 涉及他人隐私、个人信息或资料的；</div>
            <div class="p2">(5) 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</div>
            <div class="p2">(6) 含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。</div>
        </div>
        <br>
        <div class="p1">严禁通过任何方式宣传各种赌博信息或直接组织赌博行为。 严禁宣传传销组织、哄骗用户加入传销组织或组织传销。 严禁有在私信或视频通话中态度恶劣，肆意谩骂等行为。</div>
        <br>
        <div class="p1">存在以上行为的，平台将视情节轻重给予警告、封禁账号等处罚，构成犯罪的，平台有权留取证据上报公安机关。用户可以使用举报功能对违规用户进行举报，平台将会对举报。</div>
        <br><div class="p1" style="text-align: center">最终解释权归平台所有</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            bundle: '',
            bundleName: '即刻约',
        }
    },
    mounted() {
        this.bundle = this.$route.params['bundle']
        if (this.bundle == 'jky') {
            this.bundleName = '即刻约'
        }
        if (this.bundle == 'cmjy') {
            this.bundleName = '草莓交友'
        }
    }
}
</script>

<style scoped>
.main {
    width: 375px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.p0 {
    word-break:break-all;
    font-size: 20px;
}

.p1 {
    word-break:break-all;
    font-size: 10px;
}

.p2 {
    word-break:break-all;
    text-indent:2em;
    font-size: 8px;
}
</style>